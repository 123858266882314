import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { ConfigService } from '../../shared/config/services/config.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DatatableColumn } from '../../shared/customizable-table/model/datatable-header-column.model';
import { Observable } from 'rxjs';
import { ManagersAccountItem } from '../components/models/managers-account.model';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ManagersAccountService {

  public static ADMIN_SERVICE = 'AdminService';
  public static ADMIN_SERVICE_SMALL = 'adminservice';

  constructor(private authService:AuthService, private http: HttpClient, private configService: ConfigService, private userPreferencesService: UserPreferencesService, private translationService:TranslateService) { }

  getManagersAccountData(businessDate: string, showAll: boolean): Observable<ManagersAccountItem[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${ManagersAccountService.ADMIN_SERVICE}/api/ManagerAccount?showAll=${showAll}&customerCode=${customerCode}`;
    return this.http.get(address)
      .pipe(map((jsonData) => {
        return ManagersAccountItem.fromJson(jsonData);
      }));
  }

  getAccountDetails(userCode){
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${ManagersAccountService.ADMIN_SERVICE}/api/AccountDetails?usercode=${userCode}`;
    return this.http.get(address)
      .pipe(map((jsonData) => jsonData));
  }

  enableOrDisableManagersAccount(enable: boolean, userCode: string){
    const customerCode = this.userPreferencesService.getCustomerCode();
    const token = this.authService.getContextFromLocalStorage().Token;
    const address = `${this.configService.getBaseUrl()}${ManagersAccountService.ADMIN_SERVICE_SMALL}/api/ManagerAccount/Enable?customerCode=${customerCode}`;
    const params = {
      "Enable": enable,
      "UserCode": userCode
     }
    return this.http.post(address, params)
      .pipe(map((jsonData) => jsonData));
  }

  getManagersAccountColumns(){
    const managersAccountColumns = [
      new DatatableColumn('Name', this.translationService.instant('APP.COLUMN.NAME')),
      new DatatableColumn('Phone', this.translationService.instant('APP.COLUMN.PHONE')),
      new DatatableColumn('Email', this.translationService.instant('APP.COLUMN.EMAIL')), 
      new DatatableColumn('Role', this.translationService.instant('APP.COLUMN.ROLE')), 
      new DatatableColumn('AccountActive', this.translationService.instant('APP.COLUMN.STATUS')),
      new DatatableColumn('AccountActive', this.translationService.instant('APP.COLUMN.BUTTON_DISABLE')),
      new DatatableColumn('button details', this.translationService.instant('APP.COLUMN.BUTTON_DETAILS'))
      ];
  return managersAccountColumns;
  }
  unblockCustomer(data){
    data = '';
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams().set('customerCode', customerCode);
    const address = `
    ${this.configService.getBaseUrl()}AdminService/api/Customer/UnblockCustomer?customerCode=${customerCode}`;
    return this.http.put(address,{ params });
  }
}

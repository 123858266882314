export class ManagersAccountItem {
    AccountActive: boolean;
    Email: string;
    Name: string;
    Phone: string;
    Reason: string;
    RegistrationStatus: string;
    Role: string;
    UserCode: string;
  
    constructor(AccountActive: boolean, Email: string, Name: string, Phone: string, Reason: string, RegistrationStatus: string, Role: string, UserCode: string) {
        this.AccountActive = AccountActive;
        this.Email = Email;
        this.Name = Name;
        this.Phone = Phone;
        this.Reason = Reason;
        this.RegistrationStatus = RegistrationStatus;
        this.Role = Role;
        this.UserCode = UserCode;
    }
  
    static fromJson(data: any): ManagersAccountItem[] {
      const managersAccountArray: ManagersAccountItem[] = [];
      data.forEach(item => {
        managersAccountArray.push(new ManagersAccountItem(
            item.AccountActive, item.Email, item.Name, item.Phone, item.Reason, item.RegistrationStatus, item.Role, item.UserCode
        ));
      });
      return managersAccountArray;
    }
  }
  